@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@400;600;700;800;900&display=swap");
@import "../css/mixin.scss";
@import "../css/variables.scss";

* {
  scrollbar-width: thin;
  scrollbar-color: #33778e #f5f5f5;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Overpass", sans-serif;
  overflow: auto !important;
}

label {
  @include label;
}

select {
  @include selectBox;
}

input {
  @include inputBox;
}

textarea {
  resize: none;
  border-radius: 7px !important;
  border: 1px solid $inputBox-borderColor !important;
}

p {
  color: #2c3244;
  font-size: 14px;
  margin-bottom: 0 !important;
}

.preview_link {
  color: #4f9fe6;
  font-weight: 500;
  text-decoration: none;
}

.form-check-input {
  background: #daeaf8 0% 0% no-repeat padding-box;
  border-radius: 5px !important;
  opacity: 1;
}

hr {
  height: 1px;
  color: #4f9fe6;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #33778e;
}

@media all and (min-width: 768px) {
  .cx-active-sidebar {
    margin-left: 290px !important;
    transition: all 0.5s ease-in-out;
  }

  .cx-active-sidebarB {
    margin-left: 270px !important;
    transition: all 0.5s ease-in-out;
  }
}

input[type="file"] {
  display: none;
}

.card-rsponsive-img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
}

.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate("-50% -50%");
}

.modal-dialog-centered {
  justify-content: center;
}

.Vehicl-list-driverName-link {
  text-decoration: underline;
  font-size: 14px;
  letter-spacing: 0.42px;
  color: #4f9fe6 !important;
  opacity: 1;
}

.popup-specification {
  .modal-content {
    max-width: 440px;
    background: #f0f9fc 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
  }
}
.Vehicle-popup-offered {
  .modal-content {
    width: 100vw !important;
  }
}

table.dataTable thead th,
table.dataTable thead td {
  border: none !important;
}
table.dataTable {
  border: none !important;
  border-spacing: 0 10px !important;
}
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 10px !important;
}
table.dataTable thead th,
table.dataTable thead td {
  padding: 10px !important;
}
table.dataTable thead,
th {
  font-size: 12px !important;
}

table.dataTable thead .sorting {
  background-image: none !important;
}
table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:before {
  display: none !important;
}
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:after {
  display: none !important;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc {
  background: none !important;
  background-repeat: no-repeat !important;
}

.dataTables_paginate .paginate_button.current {
  color: #ffff !important;
  border: none !important;
  background: #4f9fe6 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #ffff !important;
  border: none !important;
  // background: #4FBFA4 !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #ffff !important;
  border: none !important;
  background: #4f9fe6 !important;
}

table.dataTable thead .sorting_asc {
  background: none !important;
  background-repeat: no-repeat !important;
}
.dataTables_wrapper .dataTables_length {
  float: right !important;
}
.dataTables_wrapper .dataTables_filter {
  float: left !important;
}

.dataTables_wrapper .dataTables_filter input {
  background-color: #ffff !important;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  color: red;
}
.dataTables_wrapper .dataTables_info {
  color: black !important;
  padding: 10px;
  border-radius: 5px;
  background: #4f9fe626 !important;
  font-size: 16px;
  font-weight: bold;
}

div.dataTables_wrapper div.dataTables_length select {
  width: 74px !important;
  height: 36px !important;
  background-color: #ffff !important;
}

div.dataTables_wrapper div.dataTables_filter input {
  height: 36px;
  width: 250px;
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
  margin-bottom: 30px !important;
}

.table > :not(caption) > * > * {
  border-bottom: none !important;
}

.modal-header .btn-close {
  padding: 0px 20px 0 0 !important;
  height: 10px !important;
  width: 10px !important;
}

.accordion-button::after {
  // background-image: url(../images/ic_dropdown.svg) !important;
}

.offcanvas-header .btn-close {
  padding: 5px !important;
  height: 5px !important;
  width: 5px !important;
}

.hr-line {
  border-bottom: 1px solid #edf4fc;
}

.option-dropdown ul button {
  border-radius: 5px;
  color: #66739b;
  font-size: 12px;
}

.option-dropdown ul button:hover {
  background-color: #f5f8fd;
  color: #4f9fe6;
  letter-spacing: 0.36px;
}
.option-dropdown ul {
  padding: 10px;
}
