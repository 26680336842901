@import "../css//variables.scss";
@import "../css/mixin.scss";

.main-section-reports {
  .nav-pills .nav-link {
    border-radius: 0 !important;
  }
  position: relative;
  transition: all 0.5s;
  margin: 0 0 0 70px;
  overflow-x: hidden;
  .reports-tab {
    flex-basis: 30%;
  }

  .tab-content {
    position: relative;
    flex-basis: 70%;
  }

  .nav-pills .nav-link.active {
    color: #33778e;
    background-color: #4fbfa419;

    .arrow-orange {
      position: absolute;
      right: -28px;
      top: 5px;
    }
  }

  .nav-pills .nav-link {
    color: black;
    text-align: start;
    padding: 20px;
    border-bottom: 1px solid #f6f2ef;
  }

  .nav-pills .nav-link.active:after {
    position: relative;
  }

  .reports-body-section {
    background: #fdfeff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px rgba(0, 120, 140, 0.0509803922);
    border-radius: 15px;
    font-family: "Overpass", sans-serif;
    padding: 20px;
    margin-top: 7%;
    width: 60%;
    height: auto;
  }

  .reports-card-heading {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.43px;
    color: #1f1104;
  }

  .report-btn {
    text-decoration: none;
    color: $btn-font-color2;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0.38px;
    width: 40%;
    border: none;
    background-color: #33778e;
  }
}

.reports-tab {
  background-color: #ffff;
  // width: 30%;
  height: auto;
  min-height: 100vh;
}

.tab-heading-reports {
  font: normal normal 600 16px/20px Overpass;
  letter-spacing: 0.38px;
  color: #33778e;
  text-transform: uppercase;
  padding: 15px;
}
.main-assetsReports {
  cursor: pointer;
  padding: 20px;
  background: #edf4f4 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: space-between;
}
.assetsReports {
  font-size: 16px !important;
  letter-spacing: 0.38px;
  color: #191a1a !important;
}
.arrow-orange {
  position: absolute;
  right: -28px;
  top: 5px;
}
